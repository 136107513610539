import React from "react";
import { graphql } from 'gatsby';
import get from 'lodash/get';
import L from 'leaflet';
import { isDomAvailable } from 'lib/util';
import { Helmet } from 'react-helmet';
import Layout from "../components/Layout";
import Rating from 'components/Rating';
import { Map, Marker, TileLayer } from "react-leaflet";
import styled from 'styled-components';
import SocialLinks from 'components/SocialLinks';

class RecommendationTemplate extends React.Component {
  
  render(props) { 
    const place = get(this.props, 'data.contentfulRecommendation');
    const fallbackImg = this.props.data.fallback.childImageSharp.fluid.src;
    const bgImage = place.images ? place.images[0].fixed.src : fallbackImg;

    const mapSettings = {
      center: [place.location.lat, place.location.lon],
      defaultBaseMap: "OpenStreetMap",
      zoom: 18
    }


    if (isDomAvailable()) {
      delete L.Icon.Default.prototype._getIconUrl;
      L.Icon.Default.mergeOptions({
          iconRetinaUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
          iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
          shadowUrl: require('leaflet/dist/images/marker-shadow.png')
      });
    }

    return (
      <Layout>
      <Helmet>
        <title>Best in Brighton recommendation: {place.name}</title>
      </Helmet>

      <div
	        className="page-hero"
	        style={{
	          backgroundImage: `url(${bgImage})`,
	        }}
	      >
	         <h1>{place.name}</h1>
	      </div>

      <MainContent className="container container-content">
          <Col>
            <p className="type">{place.type.name}</p>  
            <Rating stars={place.rating} />
            <p><strong>Price:</strong> {place.priceRange}</p> 
            <p>{place.description.description}</p>  

            <p><strong>Links:</strong></p>
            <SocialLinks recommendation={place} />

        
          </Col>

          <Col>
            <StyledMap {...mapSettings} scrollWheelZoom={false} touchZoom={false} doubleClickZoom={true}> 
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker
                key={place.name}
                position={[place.location.lat, place.location.lon]}
              >
              </Marker>
            </StyledMap>
            <p className="address-label"><strong>Address:</strong> {place.address.address}</p>

            <p>Is this information incorrect? Please <a href="mailto:hello@bestinbrighton.co.uk">contact us</a> to update.</p>
          </Col>
      </MainContent>
    </Layout>
    ) 
  }
}

export default RecommendationTemplate

const MainContent = styled.div`
  display: flex;

  @media(max-width: 768px) {
    flex-direction: column;
  }

  .type {
    margin-top: 0;
  }

  .address-label {
    margin-bottom: 0;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;

  @media(min-width: 768px) {
    &:first-child {
      width 70%;
      margin-right: 20px;
    }

    &:nth-child(2) {
      width: 30%;
    }
  }
`

const StyledMap = styled(Map)`
  height: 300px;
  width: 100%;

  .leaflet-control {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
`

export const RecommendationPageQuery = graphql`
  query RecommendationPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    fallback: file(relativePath: { eq: "banner-images/fallback.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, fit: COVER) {
        	src
          ...GatsbyImageSharpFluid
        }
      }
    }
    contentfulRecommendation(slug: { eq: $slug }) {
        name
        slug
        rating
        priceRange
        website
        facebook
        instagram
        twitter
        type {
          name
        }
        address {
          address
        }
        location {
          lat
          lon
        }
        description {
          description
        }
        images {
          fixed(width: 1500, height: 400) {
            ...GatsbyContentfulFixed
          } 
        }
      }

    }
  `;