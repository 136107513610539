import React from "react";
import styled from 'styled-components';
import { FaTwitter, FaFacebook, FaInstagram, FaGlobeEurope } from 'react-icons/fa';

function SocialLinks(props) {
    const recommendation = props.recommendation;
      
      function isPremium() {
        if (recommendation && recommendation.premium) {
            return (
            <>
              {recommendation.facebook !== null && <li><StyledLink href={recommendation.facebook} target="_blank"><FaFacebook /></StyledLink></li>}
              {recommendation.twitter !== null && <li><StyledLink href={recommendation.twitter} target="_blank"><FaTwitter /></StyledLink></li>}
              {recommendation.instagram !== null && <li><StyledLink href={recommendation.instagram} target="_blank"><FaInstagram /></StyledLink></li>}
            </>
            );
         } 
    
        return('');

      }

        return (
            <StyledList>
                {recommendation.website !== null && <li><StyledLink href={recommendation.website} target="_blank"><FaGlobeEurope /> <span>Website</span></StyledLink></li>}
                { isPremium() }
          </StyledList>
        );
}

export default SocialLinks;

const StyledList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: inline-flex;

        a {
            color: #fff;

            span {
                font-size: 14px;
                line-height: 1.5em;
                margin-left: 4px;
            }

            &:hover {
                text-decoration: none !important;
            }
        }
    }
`

const StyledLink = styled.a`
    text-decoration: none;
    background-color: #ff9900;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    border: 1px solid #ff9900;
    transition: all 0.5s;
    font-size: 20px;
    line-height: 0.25em;
    vertical-align: bottom;
    margin: 15px 2px 0 2px;
    align-self: start;
    display: flex;

   	&:hover {
   		background: transparent;
   		color: #ff9900;
   		border: 1px solid #ff9900;
   		cursor: pointer;
   	}
`